
body{

    /*background-color: #7ae3ae;*/
    background-color: #46c7b4;
    /*background-image: url('https://res.cloudinary.com/teewhy/image/upload/v1560460377/background_1.jpg');
    height:500px;
    !*width: 100px;*!
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover*/

}

.main{

    margin-top: 20px;

}

.auth{

    margin: 50em;
}

.link img{

    margin-top: 20px;
    /*color: white;*/
    /*font-size: 5px;*/
    margin-left: 5px;
    height: 30px;
    margin-bottom: 20px;

}

/*.links{
    margin-top: 20px;
    border-radius: 10px;
    border: 2px solid white;
    padding: 15px;
    width: 30em;
    height: 80px;
    margin-left: 30em;

}

.link-text{
    color: white;
    font-size: 23px;
    text-decoration: none;
}

.links:hover{
    background-color: white;

}

.btn-link:hover{
    color: #39e09b;
    background-color: #fff;
    border-color: #fff;
}

.btn-link{
    color: white;
}



!*.links a:hover {
    text-decoration: none;
    color: #80f5ba;
}*!*/

img{

    height: 8em;
    margin-top: 5px;
}

.links{
    margin-bottom: 12px;
    padding: 0;
    text-align: center;
    color: white;
}

.links:hover{
    color: #39e09b;
}

.btn{
    vertical-align: middle;
    border: 2px solid white;
    border-radius: 12px;
    width: 20em;
    height: 80px;
    padding: 15px;
}

.btn-link{

    border-color: white;
    color: white;
    font-size: 20px;

}

.btn-link:hover{
    color: #39e09b;
    background-color: #fff;
    border-color: #fff;
    text-decoration: none;
}

.footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
}